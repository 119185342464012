import React, { useState, useEffect } from "react";
import { ThemeProvider as StyledThemeProvider, ThemeContext } from "styled-components";

import { theme as mainTheme } from "../../theme/theme";
import { expressColors } from "../../theme/expressColors";

export interface ICustomThemeContext {
    onSetExpressTheme: (
        primaryColor?: string,
        primaryColorLight?: string,
        secondaryLightColor?: string,
        secondaryDarkColor?: string,
        isPortrait?: boolean
    ) => void;
}

export const CustomThemeContext = React.createContext<ICustomThemeContext>(null as any);

export const ThemeProvider = ({ children }: any) => {
    const [theme, setTheme] = useState(mainTheme);

    useEffect(() => {
        setTheme({ ...theme, orientation: { isPortrait: window.innerWidth < window.innerHeight } });
    }, []);

    const onSetExpressTheme = (
        primaryColor?: string,
        primaryColorLight?: string,
        expressSecondary?: string,
        secondaryDarkColor?: string
    ) => {
        const useDefaultTheme = !primaryColor && !expressSecondary && !secondaryDarkColor;
        if (!useDefaultTheme) {
            const color = {
                ...theme.colors,
                ...expressColors,
                expressPrimary: primaryColor!,
                expressPrimaryLight: primaryColorLight!,
                expressSecondaryDark: secondaryDarkColor,
                expressSecondary: expressSecondary
            };
            setTheme({ ...theme, colors: { ...color } });
        }
    };

    return (
        <CustomThemeContext.Provider value={{ onSetExpressTheme }}>
            <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export const useTheme = () => {
    const ctx = React.useContext(ThemeContext);
    ctx?.borderStyles;
    if (!ctx) {
        throw new Error("useTheme must be within ThemeProvider");
    }
    return ctx;
};

export const useCustomTheme = () => {
    const ctx = React.useContext(CustomThemeContext);
    if (!ctx) {
        throw new Error("useCustomTheme must be within ThemeProvider");
    }
    return ctx;
};
